@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --background: #ffffff;
    --foreground: #171717;
}

@media (prefers-color-scheme: dark) {
    :root {
        --background: #0a0a0a;
        --foreground: #ededed;
    }
}

body {
    color: var(--foreground);
    background: var(--background);
    font-family: Arial, Helvetica, sans-serif;
    overflow-x: hidden;
}